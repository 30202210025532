import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'ketCallback'
})
export class KetCallbackPipe implements PipeTransform {

  constructor() {
  }

  transform(v: any, cb: Function, ...parameter: any){
   return cb(v, ...parameter);
  }
}
