import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export namespace IProductReport {
  export interface MarketplaceInventory {
    id: number;
    ket_product_id: number;
    sku: string;
    channel_name: string;
    channel: string;
    channel_id: string;
    channel_inventory_id: number;
    channel_product_id: number;
    on_hand_number: number;
    reserved_number: number;
    ready_to_ship_number: number;
    available_number: number;
    created_at: string;
    updated_at: string;
    activity_by: string;
    withdraw_by?: any;
    withdraw_at?: any;
    activity_type: string;
    transaction_ref?: any;
  }

  export interface Product {
    id: number;
    rating?: any;
    review_count?: any;
    buy_limit: number;
    cate_id: number;
    barcode?: any;
    barcode_format: string;
    group?: any;
    prop_enable: number;
    title_lang1: string;
    title_lang2: string;
    title_lang3: string;
    title_lang4: string;
    status: number;
    hilight: number;
    permalink?: any;
    permalink_lang1: string;
    permalink_lang2: string;
    permalink_lang3: string;
    permalink_lang4: string;
    feature_img: string;
    feature_img_font?: any;
    image_gallery?: any;
    short_desc_lang1: string;
    short_desc_lang2: string;
    short_desc_lang3: string;
    short_desc_lang4: string;
    product_desc_lang1: string;
    product_desc_lang2: string;
    product_desc_lang3: string;
    product_desc_lang4: string;
    dynamic?: any;
    weight: number;
    price4: number;
    price3: number;
    price2: number;
    price1: number;
    price: number;
    temp_price: number;
    cost_price: number;
    unit?: any;
    max?: any;
    mix?: any;
    instock: number;
    picture1?: any;
    picture2?: any;
    picture3?: any;
    options: string;
    created_date?: any;
    updated_date?: any;
    created_by: string;
    updated_by: string;
    sku: string;
    size_width: number;
    size_length: number;
    size_height: number;
    property_info: string;
    properties_desc: string;
    properties_sort: number;
    properties_desc2?: any;
    property_info2?: any;
    properties_sort2?: any;
    group_id: string;
    uuid?: any;
    properties_main: number;
    seo_title_lang1: string;
    seo_title_lang2: string;
    seo_title_lang3: string;
    seo_title_lang4: string;
    activity_by: string;
    activity_type: string;
    transaction_ref?: any;
    marketplace_inventory: MarketplaceInventory[];
  }

  export interface ByIdResponse extends Product {
    report_summary: {
      sale_qty: number;
      reserved_order_count: number;
      reserved_qty: number;
    }
  }

  export interface InventoryReportRequest {
    product_id: number;
    start_date: string;
    end_date: string;
  }

  export interface ReportStockActivity {
    bring_forward: number;
    in: number;
    out: number;
  }

  export interface Inventory {
    id: number;
    sku: string;
    status: number;
    title_lang1: string;
    title_lang2: string;
    title_lang3: string;
    title_lang4: string;
    feature_img: string;
    property_info?: any;
    property_info2?: any;
    properties_desc?: any;
    properties_desc2?: any;
    instock: number;
    on_hand: number;
    price: number;
    temp_price: number;
    created_date?: any;
    created_by: string;
    updated_date?: any;
    updated_by: string;
    marketplace_inventory?: MarketplaceInventory[];
    prop_enable: number;
    available: number;
    reserved: number;
    product_category?: any;
    group_id: string;
  }

  export interface MarketplaceInventory {
    id: number;
    shop_id: number;
    ket_product_id: number;
    sku: string;
    channel_name: string;
    channel: string;
    channel_id: string;
    channel_inventory_id: number;
    channel_product_id: number;
    on_hand_number: number;
    reserved_number: number;
    ready_to_ship_number: number;
    available_number: number;
    created_at: string;
    updated_at: string;
    activity_by: string;
  }

  export interface MarketplaceInventoryReport extends MarketplaceInventory {
    report_stock_activity: ReportStockActivity
  }

  export interface InventoryProductReport extends Inventory {
    report_stock_activity: ReportStockActivity
    marketplace_inventory: MarketplaceInventoryReport[]
  }

  export interface InventoryReportResponse {
    product: InventoryProductReport,
    all_channel: {
      report_stock_activity: ReportStockActivity,
      instock: number;
      reserved: number;
      on_hand: number
    };
    chart_data?: {
      sale_and_reserved: {
        data: [[string, string, string], ...[string, number, number][]];
      },
      inbound_stock: {
        data: [[string, string], ...[string, number][]];
      }
    };
    start_date?: string;
    end_date?: string
  }

  export interface IModelSearchStockActivity {
    channel: string;
    channel_id: string;
    product_id: number;
    start_date: string;
    end_date: string;
    page: number;
    page_size: number;
    sort: string;
  }

  export interface StockActivityLog {
    product_id: number;
    created_by: string;
    created_at: string;
    updated_at: string;
    withdraw_by?: any;
    transaction_ref?: any;
    type: string;
    channel: string;
    channel_id: string;
    channel_name: string;
    bring_forward: number;
    in: number;
    out: number;
    new_qty: number;
  }

  export interface StockActivityResponse {
    count: number;
    data: StockActivityLog[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class KetshopwebReportService {

  constructor(
    private _http: HttpClient
  ) { }

  getProductById(product_id: any): Promise<IProductReport.ByIdResponse> {
    return this._http.get(`/services/ketshopweb-reports/products-report/byId/${product_id}`).toPromise() as Promise<IProductReport.ByIdResponse>
  }

  overview(data: IProductReport.InventoryReportRequest): Promise<IProductReport.InventoryReportResponse> {
    return this._http.post(`/services/ketshopweb-reports/products-report/overview`, data).toPromise() as Promise<IProductReport.InventoryReportResponse>
  }

  searchStockActivity(data: IProductReport.IModelSearchStockActivity): Promise<IProductReport.StockActivityResponse> {
    return this._http.post(`/services/ketshopweb-reports/products-report/search/stock-activity`, data).toPromise() as Promise<IProductReport.StockActivityResponse>
  }

  getTotalSalesPrice(input: {
    product_id: number;
    start_date: string;
    end_date: string;
  }){
    return this._http.post(`/services/ketshopweb-reports/products-report/total-sales-price/${input.product_id}`, input).toPromise() as Promise<any>
  }

}
